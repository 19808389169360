<template>
  <div class="addfacilitator">
    <el-form ref="form" :model="formData" :rules="rules" label-width="40px">
      <div class="group">
        <div class="top">
          <div class="upload-box">
            <el-form-item prop="idcardFrontUrl" label-width="0">
              <MyUpLoad
                v-model="formData.idcardFrontUrl"
                name="法人身份证国徽面"
                disabled
                type="1"
                @idcardCertificate="changeIdcards"
                validate="idcardFrontUrl"
              />
            </el-form-item>
            <el-form-item prop="idcardBackUrl" label-width="0">
              <MyUpLoad
                v-model="formData.idcardBackUrl"
                name="法人身份证人像面"
                type="2"
                disabled
                validate="idcardBackUrl"
                @ocrIdcardCertificate="changeIdcard"
              />
            </el-form-item>
            <el-form-item prop="businessLicenseUrl" label-width="0">
              <MyUpLoad
                v-model="formData.businessLicenseUrl"
                type="3"
                disabled
                name="营业执照"
                validate="businessLicenseUrl"
                @tradingCertificate="changeTrading"
              />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="title">认证信息</div>
      <div class="group">
        <div class="line">
          <el-form-item label=" " prop="companyName">
            <el-input
              disabled
              v-model.trim="formData.companyName"
              placeholder="请输入企业名称"
              maxlength="64"
              prefix-icon="hy-icon-yinhang"
            ></el-input>
          </el-form-item>
          <el-form-item label=" " prop="legalName">
            <el-input
              disabled
              v-model.trim="formData.legalName"
              placeholder="请输入法人名称"
              maxlength="20"
              prefix-icon="hy-icon-shenfenzheng"
            ></el-input>
          </el-form-item>
        </div>
        <div class="line">
          <el-form-item label=" " prop="socialCode">
            <el-input
              disabled
              v-model="formData.socialCode"
              placeholder="请输入统一信用代码"
              prefix-icon="hy-icon-yinhang"
              maxlength="30"
            ></el-input>
          </el-form-item>
          <el-form-item label=" " prop="legalIdcard">
            <el-input
              v-model="formData.legalIdcard"
              disabled
              placeholder="请输入法人身份证号"
              prefix-icon="hy-icon-shenfenzheng"
              maxlength="18"
            ></el-input>
          </el-form-item>
        </div>
        <div class="line">
          <el-form-item label=" " prop="dictIdList">
            <div class="select_dict">
              <img src="../../../assets/zxx_img/fuwu.png" alt="" />
              <el-select
              multiple
              v-model="formData.dictIdList"
              placeholder="请选择服务"
            >
              <el-option
                v-for="item in options"
                :key="item.dictId"
                :label="item.dictValue"
                :value="item.dictId"
              >
              </el-option>
            </el-select>
            </div>
            
          </el-form-item>
        </div>
      </div>
      <div class="title">
        服务商资质图片<span>（最多上传50张资质图片）</span>
      </div>
      <div class="group">
        <div class="lines">
           <el-upload
            action="#"
            list-type="picture-card"
            :before-upload="beforeupload"
            :http-request="addinvoiceUrl"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
             accept=".jpg, .jpeg, .png,.bmp"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <!-- <el-upload
            action="#"
            :on-change="handleChange"
            :http-request="addinvoiceUrl"
            accept=".jpg,.png,.jpeg"
            multiple
            :file-list="fileList"
            :limit="50"
            list-type="picture-card"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span> -->
                <!-- <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload> -->
        </div>
        <div class="btn-wrap">
          <el-button @click="submit" class="hy-btn">提交认证</el-button>
        </div>
      </div>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
function insertStr(soure, start, newStr) {
  return soure.slice(0, start) + newStr + soure.slice(start);
}
import { listByDictType,ossUploadFile,serviceProvider } from "@/api/public";
import { reqCompanyAuthDetail } from "@/api/hy";
import Vue from "vue";
import * as types from "@/store/action-types";
export default {
  data() {
    const validateLegerName = (rule, value, callback) => {
      let validate = /^[\u4e00-\u9fa5]{2,20}$/;
      if (!validate.test(value)) {
        return callback(new Error("法人名称只能输入2-20位汉字"));
      } else {
        return callback();
      }
    };
    return {
      imgList:[],
      fileList: [],
      content: {},
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      options: [],
      formData: {
        enterpriseId: Vue.ls.get(types.enterpriseId),
        idcardFrontUrl: "",
        idcardBackUrl: "",
        businessLicenseUrl: "",
        legalName: "",
        companyName: "",
        legalIdcard: "",
        socialCode: "",
        idcardValidityTerm: null,
        accountNumber: "",
        accountName: "",
        accountBank: "",
        attorneyPowerUrl: "",
        auditStatus: null,
        ifLong: false,
        companyTypes: [],
      },
      rules: {
        legalName: [
          { required: true, message: "请输入法人名称", trigger: "blur" },
          { validator: validateLegerName, trigger: "blur" },
        ],
        idcardFrontUrl: [
          {
            required: true,
            message: "请上传法人身份证国徽面",
            trigger: "change",
          },
        ],
        idcardBackUrl: [
          {
            required: true,
            message: "请上传法人身份证人像面",
            trigger: "change",
          },
        ],
        businessLicenseUrl: [
          { required: true, message: "请上传营业执照", trigger: "change" },
        ],
        companyName: [
          { required: true, message: "请填写企业名称", trigger: "blur" },
          {
            min: 1,
            max: 99,
            message: "长度在 1 到 99 个字符",
            trigger: "blur",
          },
        ],
        legalIdcard: [
          { required: true, message: "请填写法人身份证号", trigger: "blur" },

          {
            pattern:
              /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,
            message: "请输入有效身份证号码",
            trigger: "blur",
          },
        ],
        dictIdList: [
          { required: true, message: "请选择服务类型", trigger: "blur" },
        ],
        socialCode: [
          { required: true, message: "请填写统一信用代码", trigger: "blur" },
          {
            pattern: /^[A-Za-z0-9]{1,30}$/,
            message: "请输入有效统一信用代码",
            trigger: "blur",
          },
          { min: 1, max: 30, trigger: "blur" },
        ],

        accountNumber: [
          { required: true, message: "请填写收款账号", trigger: "blur" },
          {
            pattern: /^[0-9]{1,30}/,
            message: "请输入有效收款账号",
            trigger: "blur",
          },
          { min: 1, max: 30, trigger: "blur" },
        ],
        accountName: [
          { required: true, message: "请填写收款户名", trigger: "blur" },
          {
            pattern: /^[\u4E00-\u9FA5]{1,30}$/,
            message: "请输入正确收款户名",
            trigger: "blur",
          },
          { min: 1, max: 30, trigger: "blur" },
        ],
        accountBank: [
          { required: true, message: "请填写收款银行", trigger: "blur" },
        ],
        attorneyPowerUrl: [
          { required: true, message: "请上传委托书", trigger: "change" },
        ],
        companyTypes: [
          { required: true, message: "请选择企业类型", trigger: "change" },
        ],
      },
    };
  },
  components: {
    MyUpLoad: () =>
      import("../../userCenter/authentication/components/MyUpLoad.vue"),
  },
  mounted() {
    this.getCompanyDetail();
    this.getparentLevel();
  },
  methods: {
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-50);
    },
    //上传图片格式大小检查
    checkPic(file) {
      let isJPG;
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg"
      ) {
        isJPG = true;
      } else {
        isJPG = false;
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error("请上传jpg,jpeg,png,bmp图片!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
        return false;
      }
      return true;
    },
    beforeupload(file){
      console.log('上传之前',file)
      let pass = this.checkPic(file);
      if(!pass){
        return false
      }
    },
    addinvoiceUrl(file) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFile(formData).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.imgList.push(res.data)
          }
        });
    },
    getparentLevel() {
      let dictType = "service_range";
      listByDictType(dictType).then((res) => {
        if (res.code == 0) {
          this.options = res.data;
        }
      });
    },
    async getCompanyDetail() {
      const ret = await reqCompanyAuthDetail({
        enterpriseId: Vue.ls.get(types.enterpriseId),
      });
      console.log(ret);
      if (ret.code == 0) {
        this.bindData(ret.data);
      }
    },
    bindData(data) {
      this.formData = data;
      if (data.idcardValidityTerm != null && data.idcardValidityTerm != "") {
        try {
          this.formData.idcardValidityTerm = data.idcardValidityTerm.split("-");
        } catch (e) {
          console.log(e);
        }
      } else {
        this.formData.idcardValidityTerm = null;
      }
    },
    handleRemove() {
       for (let i = 0; i < this.imgList.length; i++) {
          this.imgList.splice(i, 1);
      }
      console.log(this.imgList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    changeIdcards(data) {
      let arr = data.timelimit.split("-");
      let art = [4, 7];
      arr.forEach((it, i) => {
        art.forEach((item) => {
          arr[i] = insertStr(arr[i], item, ".");
        });
      });
      this.formData.idcardValidityTerm = [...arr];
    },
    changeIdcard(data) {
      this.formData.legalIdcard = data.number;
    },
    changeTrading(data) {
      this.formData.companyName = data.name;
      this.formData.legalName = data.owner;
      this.formData.socialCode = data.code;
    },
    formDataaCcountNumber(e) {
      this.formData.accountNumber = e;
    },
    submit() {
      if(!this.formData.dictIdList){
        this.$message.error("请选择服务类型");
      }else if(this.imgList.length==0){
        this.$message.error("请上传服务商资质图片");
      }else{
        let config ={
          dictIdList:this.formData.dictIdList,
          imgUrlList:this.imgList
        }
        serviceProvider(config).then(res=>{
          console.log(res)
          if(res.code==0){
            this.$message({
              message: "提交成功,等待平台审核",
              type: "success",
            });
            this.$router.go(-1)

          }
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .select_dict{
  border: 1px solid #DCDFE6;
  display: flex;
  align-items: center;
  width: 360px;
  .el-input__inner{
    border: none;
  }
  img{
    width: 14px;
    height: 16px;
    margin-left: 5px;
  }
}
.addfacilitator {
  width: 65%;
  min-width: 1200px;
  margin: 0 auto;
}
::v-deep .el-form-item__error {
  min-width: 800px;
}
.el-range-separator {
  border: 1px solid blue;
}
.authentication {
  width: 1195px;
  margin: 15px auto;
  background: white;
  &::before,
  &::after {
    display: table;
    content: "";
  }
}
.state-box {
  margin: 20px 0;
  text-align: right;
}
.entrust {
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    p {
      margin-right: 40px;
      color: #2084d6;
    }
    span {
      cursor: pointer;
      & + span {
        margin-left: 5px;
      }
    }
  }
}
.title {
  font-size: 18px;
  font-weight: bold;
  font-family: PingFang SC;
  padding: 14px 15px;
  border: 1px solid #e5e5e5;
  border-left: none;
  border-right: none;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    color: red;
    font-weight: normal;
    font-size: 14px;
  }
}
.upload-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom {
  text-align: center;
  margin-bottom: 40px;
  .checked {
    display: flex;
    align-items: center;
    justify-content: center;
    &::v-deep .el-radio {
      margin-right: 0;
    }
  }

  .tip {
    color: red;
  }
}
.btn-wrap {
  text-align: center;
  .el-button {
    width: 308px;
  }
}
.remove {
  cursor: pointer;
  margin-left: 10px;
  vertical-align: middle;
}
.group {
  padding: 32px;
  background: white;
}
.line {
  display: flex;
  align-items: center;
  margin-left: 140px;
  .el-input {
    width: 360px;
  }
  .el-select {
    width: 360px;
  }
}
.lines {
  .lines_up {
    margin-left: 20px;
    margin-top: 20px;
  }
}
.entrust {
  margin-left: 104px;
  .hy-btn {
    width: 163px;
  }
}
.download,
.agreement {
  cursor: pointer;
  color: #3889fb;
}
</style>